.apiGroupTable {
    .column-showId {
        width: 10%;
    }
    .column-moduleId {
        width: 10%;
    }
    .column-name {
        width: 25%;
    }
    .column-description {
        width: 25%;
    }
}
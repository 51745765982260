header {
    &.MuiAppBar-root {
        transform: none !important;
        visibility: visible !important;
    }
}
.RaDatagrid-table {
    .css-blmy2t-MuiTableCell-root {
        width: 3%;
    }
    .column-undefined {
        padding-left: 0;
        padding-right: 0;
        width: 3px;
    }
    thead {
        tr {
            th {
                font-weight: bold;
            }
        }
    }
}
.MuiDrawer-root {
    margin-top: 0px !important;
}
.dut-loading {
    .dut-loading-bg {
        position: fixed;
        background-color: #fff;
        opacity: 0.6;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100000000;
        &.white {
            opacity: 1;
        }
    }
    .dut-loading-icon {
        position: fixed;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        width: 50px;
        height: 50px;
        z-index: 100000001;
    }

    .dut-linear-progress-bar {
        position: fixed;
        top: calc(50% - 25px);
        left: calc(50% - 250px);
        height: 50px;
        width: 500px;
        z-index: 100000001;
    }
}
.submenu {
    .list-item {
        padding-left: 15px;
    }
    .list-item-icon {
        min-width: 40px;
    }
    .list-item-text {
        padding-left: 0px;
        span, p {
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }   
    .list-child-menu {
        padding-left: 15px;
        transition: padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        a {
            font-size: 1rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        .RaMenuItemLink-icon {
            min-width: 30px;
        }
    }
    &.RaMenu-closed {
        .list-child-menu {
            padding-left: 0;
            .RaMenuItemLink-icon {
                min-width: 40px;
            }
        }
    }
}

.no-access {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .no-access-message {
        text-align: center;
        font-family: Roboto, sans-serif;
        opacity: 0.5;
        margin: 0 1em;
    }
    .no-access-icon {
        width: 9em;
        height: 9em;
    }
    .no-access-toolbar {
        text-align: center;
        margin-top: 2em;
    }
}

.dut-form form {
    .MuiCardContent-root {
        margin-bottom: 64px;
        min-height: calc(100vh - 280px);
    }
    
    .MuiToolbar-root {
        position: fixed;
        width: calc(100% - 312px);
        bottom: 0px;
        z-index: 1;
    }
}

.column-undefined a {
    min-width: 3.5em;
}
.column-undefined button {
    min-width: 3.5em;
}
.column-undefined span {
    margin-right: 0px;
}

.pointerClass:hover {
    cursor: pointer;
}

.alignItems-class {
    display: flex;
    align-items: center;
}
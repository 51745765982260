.studentListToolBar {
    min-height: 30px !important;
}

td:has(> a.parentPaddingNone) {
    padding: 0;
}

td:has(> button.parentPaddingNone) {
    padding: 0;
}
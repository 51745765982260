.borderTop{
    border-top: solid 1px rgba(0, 0, 0, 0.12);
}


.newAvatar {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1.5em;
}

.newAvatar p {
    display: none;
}
.table-permission {
    .MuiFormControlLabel-root {
        margin-left: 0px;
    }
    .MuiCheckbox-root {
        padding: 0px 10px 0px 0px;
    }
    .MuiTableCell-root {
        padding: 5px;
    }
    .row-function td {
        padding: 15px 15px 15px 30px;
    }
    .row-screen .MuiFormControlLabel-label {
        font-weight: 600;
    }
}

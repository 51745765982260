.active-module {
    margin-left: 7%;
}
.center-checkbox {
    margin-top: 4%;
    margin-left: 20%;
}
.table-item {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    padding: 15px 15px 15px 15px;
}
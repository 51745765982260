.rowfunction .RaSimpleFormIterator-line .RaSimpleFormIterator-form{
    display: flex;
    flex-direction: row;
    margin-top: 1.5%;
}
.isView{
    margin-top: 1%;
}
.rowfunction .RaSimpleFormIterator-line .RaSimpleFormIterator-action{
    margin-top: 2%;
}
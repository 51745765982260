.apiTable {
    .column-showId {
        width: 5%;
    }
    .column-moduleId {
        width: 5%;
    }
    .column-groupName {
        width: 10%;
    }
    .column-apiCode {
        width: 20%;
    }
    .column-name {
        width: 20%;
    }
    .column-area {
        width: 10%;
    }
    .column-controller {
        width: 10%;
    }
    .column-action {
        width: 10%;
    }
    .column-undefined {
        width: 2%;
    }
}